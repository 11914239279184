import React, {
  PureComponent,
  createRef,
  RefObject,
  SyntheticEvent
} from "react";
import { cleanPunctuation } from "libs/utils";
import { Skeleton } from "components/Skeleton/Skeleton";

import "./ImageElement.css";

interface ImageElementPropsInterface {
  src: string;
  isFullWidth?: boolean;
  caption?: string;
  altText?: string;
}

interface ImageElementStateInterface {
  hasLoaded: boolean;
  hasError: boolean;
}

export class ImageElement extends PureComponent<
  ImageElementPropsInterface,
  ImageElementStateInterface
> {
  public constructor(props: ImageElementPropsInterface) {
    super(props);
    this.state = {
      hasLoaded: false,
      hasError: false
    };
  }

  private imgRef: RefObject<HTMLImageElement> = createRef();

  private config = {
    rootMargin: "0px 0px 50px 0px",
    threshold: 0
  };

  private handleLoad = (): void => {
    this.setState({ hasLoaded: true });
  };

  private handleError = (
    err: SyntheticEvent<HTMLImageElement, Event>
  ): void => {
    const { src } = err.currentTarget;
    console.warn(`Error loading image ${src}`);
    this.setState({ hasError: true });
  };

  public render(): JSX.Element {
    const { hasLoaded } = this.state;
    const {
      src,
      altText = "Missing alt text",
      caption,
      isFullWidth
    } = this.props;

    return (
      <figure
        className={`image_element__wrapper ${
          hasLoaded ? "" : "image_element--loading"
        }`}
      >
        {hasLoaded === false && <Skeleton height="200px" />}

        <img
          alt={altText}
          className={`image_element__image ${
            isFullWidth ? "image_element__image--full_width" : ""
          }`}
          onError={this.handleError}
          onLoad={this.handleLoad}
          ref={this.imgRef}
          sizes="(min-width: 1008px) 620px, (min-width: 730px) 700px, (min-width: 480px) 450px, 100vw"
          src={`${src}?imwidth=450`}
          srcSet={`${src}?imwidth=450 450w,
            ${src}?imwidth=620 620w,
            ${src}?imwidth=700 700w,
            ${src}?imwidth=900 900w,
            ${src}?imwidth=1240 1240w,
            ${src}?imwidth=1400 1400w`}
        />

        {caption && (
          <figcaption className="image_element__caption">
            {cleanPunctuation(caption)}
          </figcaption>
        )}
      </figure>
    );
  }
}
