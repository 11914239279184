import React from "react";
import { handleEnterKeyPress } from "libs/utils";
// @ts-ignore
import relativeDate from "tiny-relative-date";
import "./SearchResults.css";
import { ArticleType } from "reducers/articleReducer";
import { store } from "store/storeConfig";

interface PropsInterface {
  articleIds: string[];
  searchResults: { id: string; text: string }[];
  fetchingArticles: boolean;
  selectArticle: (id: string) => void;
  selectSection: (id: string) => void;
}

function SearchResult(props: PropsInterface): JSX.Element {
  const {
    fetchingArticles,
    searchResults,
    selectArticle,
    selectSection,
    articleIds
  } = props;

  const { articles } = store.getState();
  const articleResults = articleIds
    .map((id): ArticleType => articles[id])
    .filter((article): boolean => article !== undefined);

  return (
    <div className="sidebar__search-results">
      <section className="sidebar__result-block sidebar__result-block--sections">
        <h2 className="sidebar__list-heading">Sections</h2>
        <ul className="sidebar__result-list sidebar__result-list--sections">
          {searchResults.slice(0, 12).map(
            (result): JSX.Element => {
              return (
                <li
                  className="sidebar__search-result-item"
                  key={`result_${result.id}`}
                >
                  <button
                    className="sidebar__search-result-btn"
                    onClick={(): void => {
                      selectSection(result.id);
                    }}
                    onKeyDown={handleEnterKeyPress((): void => {
                      selectSection(result.id);
                    })}
                  >
                    {result.text}
                  </button>
                </li>
              );
            }
          )}
        </ul>
      </section>

      <section className="sidebar__result-block sidebar__result-block--articles">
        <h2 className="sidebar__list-heading">Articles</h2>

        {fetchingArticles ? (
          <p className="sidebar__article_fetching">Loading results...</p>
        ) : (
          <ul className="sidebar__result-list sidebar__result-list--articles">
            {articleResults.map(
              (article): JSX.Element => {
                return (
                  <li className="sidebar__article_item" key={article.id}>
                    <button
                      className="sidebar__article_link sidebar__search-result-btn"
                      onClick={(): void => {
                        selectArticle(article.id);
                      }}
                    >
                      <p className="sidebar__article_title">{article.title}</p>

                      <p className="sidebar__article_description">
                        {article.snippet}
                      </p>
                      {article.datePublished && (
                        <time
                          className="sidebar__article_date"
                          dateTime={article.datePublished}
                        >
                          {relativeDate(article.datePublished)}
                        </time>
                      )}
                    </button>
                  </li>
                );
              }
            )}
          </ul>
        )}
      </section>
    </div>
  );
}

export default SearchResult;
