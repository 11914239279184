import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Card } from "components/Card/Card";
import { ListView, WhiteSpace } from "antd-mobile";
import { ArticleType } from "reducers/articleReducer";
import { StateInterface } from "reducers/rootReducer";
import { selectArticles } from "selectors/articleSelectors";
import { Skeleton } from "components/Skeleton/Skeleton";
import {
  loadSectionContent,
  EditionSectionInterface
} from "actions/EditionActions";
import "./SectionList.css";

interface DispatchProps {
  loadSectionContent: typeof loadSectionContent;
}

interface PropsInterface extends MapStatePropsInterface, DispatchProps {
  activeEdition: string;
  activeSection: string;
}

class SectionList extends PureComponent<PropsInterface> {
  public componentDidMount(): void {
    const {
      loaded,
      activeEdition,
      loadSectionContent,
      activeSection
    } = this.props;

    if (loaded === false && activeSection) {
      loadSectionContent(activeSection, activeEdition);
    }
  }

  public componentDidUpdate(prevProps: PropsInterface): void {
    const {
      activeSection,
      loaded,
      activeEdition,
      loadSectionContent,
      fetching
    } = this.props;

    if (
      activeSection &&
      activeSection !== prevProps.activeSection &&
      loaded === false &&
      fetching === false
    ) {
      loadSectionContent(activeSection, activeEdition);
    }
  }

  private dataSource = new ListView.DataSource({
    rowHasChanged: (): void => console.log("rowHasChanged")
  });

  public handleCardClick(articleId: string): void {
    history.pushState(null, "", `${location.href}/${articleId}`);
  }

  public render(): JSX.Element {
    const { articleData, loaded: isSectionLoaded, activeSection } = this.props;

    if (activeSection === undefined) {
      return (
        <div className="section_list__error">
          Uh oh! Section ID is missing. Maybe sections did not load?
        </div>
      );
    }

    if (isSectionLoaded === false) {
      return (
        <div className="section_list__loading">
          <p className="section_list_loading_text">Fetching section data...</p>
          <Skeleton />
        </div>
      );
    }

    return (
      <ListView
        className="section_list"
        dataSource={this.dataSource.cloneWithRows(articleData)}
        pageSize={4}
        renderRow={(props): JSX.Element => {
          return (
            <>
              <Card
                {...props}
                handleClick={(): void => this.handleCardClick(props.id)}
                key={props.id}
              />
              <WhiteSpace />
            </>
          );
        }}
      />
    );
  }
}

interface MapStatePropsInterface
  extends Pick<EditionSectionInterface, "loaded" | "fetching"> {
  articleData: ArticleType[];
}

function mapStateToProps(
  state: StateInterface,
  ownProps: PropsInterface
): MapStatePropsInterface {
  const DEFAULT_SECTION = "news-lv0";
  const { activeEdition, activeSection } = ownProps;
  const { sections } = state.editions[activeEdition];

  const sectionId = activeSection ? activeSection : DEFAULT_SECTION;
  const { fetching = false, loaded = false } = sections[sectionId] || {};

  const edition = state.editions[activeEdition];

  const packages =
    (edition &&
      activeSection &&
      edition.sections[activeSection] &&
      edition.sections[activeSection].packages) ||
    [];

  const articles = packages
    .map((packageId): string[] => edition.packages[packageId].articles)
    .flat();

  const articleData = selectArticles(articles, state.articles);

  return {
    loaded,
    fetching,
    articleData
  };
}

export default connect(
  mapStateToProps,
  { loadSectionContent }
)(SectionList);
