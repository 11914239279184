import React from "react";
import { handleEnterKeyPress } from "libs/utils";
import { SectionInterface, MiniSectionInterface } from "data/sections";
import { SECTIONS } from "data/sections";

function getChildSections(id: string): SectionInterface[] {
  return Object.values(SECTIONS).filter(
    (section): boolean => section.parent === id
  );
}

interface PropsInterface {
  sections: MiniSectionInterface[];
  selectSection: (id: string) => void;
  menuType: string | undefined;
  selectedId: string | undefined;
}

export function SectionNavigation(props: PropsInterface): JSX.Element {
  const { sections, selectSection, menuType = "", selectedId } = props;

  const sectionList = sections
    .filter((s): boolean => s.id !== "top-stories")
    .map(
      (section): JSX.Element => {
        return (
          <li
            className={`sidebar--cluster ${
              selectedId === section.id ? "sidebar--cluster__selected" : ""
            }`}
            key={section.id}
          >
            <button
              className="sidebar--nav-btn sidebar--cluster-btn"
              onClick={(): void => {
                selectSection(section.id);
              }}
              onKeyDown={handleEnterKeyPress((): void => {})}
            >
              {section.text}
            </button>
            <ul className="sidebar--section">
              {getChildSections(section.id).map(
                (s): JSX.Element => {
                  return (
                    <li className="sidebar--section-item" key={s.id}>
                      <button
                        className="sidebar--nav-btn sidebar--section-btn"
                        onClick={(): void => {
                          selectSection(s.id);
                        }}
                        onKeyDown={handleEnterKeyPress((): void => {
                          selectSection(s.id);
                        })}
                      >
                        {s.text}
                      </button>
                    </li>
                  );
                }
              )}
            </ul>
          </li>
        );
      }
    );

  return (
    <ul className={`sidebar--nav sidebar--nav__${menuType}`}>{sectionList}</ul>
  );
}
